var __jsx = React.createElement;
import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
var useStyles = makeStyles(function (theme) {
  return {
    title: {
      marginTop: 48,
      fontSize: 32,
      fontWeight: 400,
      padding: '0 12px',
      textAlign: 'center',
      fontFamily: 'Open Sans, sans-serif',
      color: '#525252',
      letterSpacing: '0px',
      lineHeight: '43px',
      '@media only screen and (max-width: 1024px)': {
        marginTop: 10
      },
      '@media only screen and (max-width: 520px)': {
        fontSize: 20,
        marginTop: 10,
        fontWeight: 400,
        lineHeight: '24px',
        letterSpacing: '1.6px'
      }
    }
  };
});

var Section = function Section(_ref) {
  var children = _ref.children,
      title = _ref.title,
      className = _ref.className,
      variant = _ref.variant;
  var classes = useStyles();
  return __jsx(Box, {
    className: className
  }, title && __jsx(Typography, {
    className: classes.title,
    variant: variant
  }, title), children);
};

Section.defaultProps = {
  children: null,
  title: null,
  className: null,
  variant: 'h2'
};
export default Section;